import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    // API Service
    ApiService.vueInstance.axios.defaults.baseURL =
      "https://api.teknologikartu.com/";
  }

  public static postWithDataKatalis(resource: string, data: any): Promise<any> {
    // return ApiService.vueInstance.axios.post(`${resource}`, data);
    return axios.post("https://api.katalis.info/" + resource, data);
  }

  public static getDownloadKatalis(resource: string): Promise<AxiosResponse> {
    const instance = axios.create({ responseType: "blob" });
    return instance.get("https://api.katalis.info/" + resource);
  }

  public static idSettingInvoice() {
    return "6410825ee8e943dc330f2102";
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios
      .get(`${resource}/${slug}`)
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  }

  public static getWithData(resource: string, data: any): Promise<any> {
    return ApiService.vueInstance.axios.get(`${resource}`, data);
  }

  public static getWithoutSlug(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`);
  }

  public static getDownload(resource: string): Promise<AxiosResponse> {
    const instance = ApiService.vueInstance.axios.create({
      responseType: "blob",
    });
    return instance.get(`${resource}`);
  }

  public static postWithoutSlug(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`);
  }

  public static postWithData(resource: string, data: any): Promise<any> {
    return ApiService.vueInstance.axios.post(`${resource}`, data);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  public static PostMethodWithoutData(resource: string): Promise<any> {
    return ApiService.vueInstance.axios.post(`${resource}`);
  }

  public static PutMethodWithoutData(resource: string): Promise<any> {
    return ApiService.vueInstance.axios.put(`${resource}`);
  }

  public static PostMethod(resource: string, data: any): Promise<any> {
    return ApiService.vueInstance.axios.post(`${resource}`, data);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  public static putWithData(resource: string, data: any): Promise<any> {
    return ApiService.vueInstance.axios.put(`${resource}`, data);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    // return ApiService.vueInstance.axios.delete(resource).catch((error) => {
    //   throw new Error(`[RWV] ApiService ${error}`);
    // });
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
