import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { components } from "@/assets/ts";

const routes: Array<RouteRecordRaw> = [

  // role Superadmin
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/superadmin/dashboard",
        name: "superadmin-dashboard",
        component: () => import("@/views/superadmin/dashboard/dashboard.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile.vue"),
      },
      {
        path: "/superadmin/notification",
        name: "superadmin-notification",
        component: () => import("@/views/superadmin/notification/notification.vue"),
      },

      // router user
      {
        path: "/superadmin/user",
        name: "user",
        component: () => import("@/views/superadmin/user/user.vue"),
      },
      {
        path: "/superadmin/user/add",
        name: "add-user",
        component: () => import("@/views/superadmin/user/add-user.vue"),
      },
      {
        path: "/superadmin/user/:id",
        name: "user/update-user",
        component: () => import("@/views/superadmin/user/edit-user.vue"),
      },
      {
        path: "/superadmin/user/detail/:id",
        name: "user/detail-user",
        component: () => import("@/views/superadmin/user/detail-user.vue"),
      },

      // router bank pusat
      {
        path: "/superadmin/bank-center",
        name: "bank",
        component: () => import("@/views/superadmin/bank/bank.vue"),
      },
      {
        path: "/superadmin/bank-center/add",
        name: "add-bank",
        component: () => import("@/views/superadmin/bank/add-bank.vue"),
      },
      {
        path: "/superadmin/bank-center/:id",
        name: "bank-center/update-bank",
        component: () => import("@/views/superadmin/bank/edit-bank.vue"),
      },
      {
        path: "/superadmin/bank-center/detail/:id",
        name: "detail-bank",
        component: () => import("@/views/superadmin/bank/detail-bank.vue"),
      },

      // router area bank
      {
        path: "/superadmin/area",
        name: "area",
        component: () => import("@/views/superadmin/area/area.vue"),
      },
      {
        path: "/superadmin/area/add",
        name: "add-area",
        component: () => import("@/views/superadmin/area/add-area.vue"),
      },
      {
        path: "/superadmin/area/:idBank/:idArea",
        name: "area/update-area",
        component: () => import("@/views/superadmin/area/edit-area.vue"),
      },
      {
        path: "/superadmin/area/detail/:idBank/:idArea",
        name: "detail-area",
        component: () => import("@/views/superadmin/area/detail-area.vue"),
      },

      // router cabang bank

      {
        path: "/superadmin/branch",
        name: "branch",
        component: () => import("@/views/superadmin/branch/branch.vue"),
      },
      {
        path: "/superadmin/branch/add",
        name: "add-branch",
        component: () => import("@/views/superadmin/branch/add-branch.vue"),
      },
      {
        path: "/superadmin/branch/:idBank/:idArea/:idBranch",
        name: "branch/update-branch",
        component: () => import("@/views/superadmin/branch/edit-branch.vue"),
      },
      {
        path: "/superadmin/branch/detail/:idBank/:idArea/:idBranch",
        name: "detail-branch",
        component: () => import("@/views/superadmin/branch/detail-branch.vue"),
      },

      {
        path: "/superadmin-client",
        name: "client",
        component: () => import("@/views/superadmin/client/client.vue"),
      },

      // router sales & marketing

      // router sales & marketing / sales / marketing
      {
        path: "/superadmin/sales/marketing/",
        name: "marketing",
        component: () => import("@/views/superadmin/salesMarketing/marketing/marketing.vue"),
      },

      // router sales & marketing / sales / sales area
      {
        path: "/superadmin/sales/sales-area/",
        name: "sales-area",
        component: () => import("@/views/superadmin/salesMarketing/sales-area/sales-area.vue"),
      },
      {
        path: "/superadmin/sales/sales-area/add",
        name: "add-sales-area",
        component: () => import("@/views/superadmin/salesMarketing/sales-area/add-sales-area.vue"),
      },
      {
        path: "/superadmin/sales/sales-area/:id",
        name: "update-sales-area",
        component: () => import("@/views/superadmin/salesMarketing/sales-area/edit-sales-area.vue"),
      },
      {
        path: "/superadmin/sales/sales-area/detail/:id",
        name: "detail-sales-area",
        component: () => import("@/views/superadmin/salesMarketing/sales-area/detail-sales-area.vue"),
      },

      // router sales & marketing / implementator
      {
        path: "/superadmin/sales/implementator/",
        name: "implementator",
        component: () => import("@/views/superadmin/salesMarketing/implementator/implementator.vue"),
      },

      // router sales & marketing / cs & after sales
      {
        path: "/superadmin/sales/cs/",
        name: "cs",
        component: () => import("@/views/superadmin/salesMarketing/cs/cs.vue"),
      },

      // router legal - legalitas
      {
        path: "/superadmin/legal/legalitas",
        name: "legal-legalitas",
        component: () => import("@/views/superadmin/legal/legalitas/legalitas.vue"),
      },
      {
        path: "/superadmin/legal/legalitas/detail/:id",
        name: "detail-legalitas",
        component: () => import("@/views/superadmin/legal/legalitas/detail-legalitas.vue"),
      },
      {
        path: "/superadmin/legal/legalitas/detail/update/:idSekolah/:idDokumen",
        name: "detail-update-legalitas",
        component: () => import("@/views/superadmin/legal/legalitas/detail-edit-legalitas.vue"),
      },
      {
        path: "/superadmin/legal/konfirmasiLegal",
        name: "legal-konfirmasiLegal",
        component: () => import("@/views/superadmin/legal/konfirmasiLegal/konfirmasiLegal.vue"),
      },
      {
        path: "/superadmin/legal/data-npwp",
        name: "data-npwp",
        component: () => import("@/views/superadmin/legal/npwp/data-npwp.vue"),
      },


      // router legal - dokumen
      {
        path: "/superadmin/legal/dokumen",
        name: "legal-dokumen",
        component: () => import("@/views/superadmin/legal/dokumen/dokumen.vue"),
      },
      {
        path: "/superadmin/legal/dokumen/add",
        name: "add-dokumen",
        component: () => import("@/views/superadmin/legal/dokumen/add-dokumen.vue"),
      },
      {
        path: "/superadmin/legal/dokumen/:id",
        name: "update-dokumen",
        component: () => import("@/views/superadmin/legal/dokumen/edit-dokumen.vue"),
      },
      {
        path: "/superadmin/legal/dokumen/detail/:id",
        name: "detail-dokumen",
        component: () => import("@/views/superadmin/legal/dokumen/detail-dokumen.vue"),
      },

      // router legal - admin legal
      {
        path: "/superadmin/legal/admin-legal",
        name: "legal-admin-legal",
        component: () => import("@/views/superadmin/legal/admin-legal/admin-legal.vue"),
      },
      {
        path: "/superadmin-finance",
        name: "finance",
        component: () => import("@/views/superadmin/finance/finance.vue"),
      },
      {
        path: "/superadmin-kartu",
        name: "kartu",
        component: () => import("@/views/superadmin/kartu/kartu.vue"),
      },
      {
        path: "apps/generatekartu/userGenerateKartu",
        name: "generate kartu",
        component: () => import("@/components/page-layouts/GenerateKartu.vue"),
      },
      {
        path: "apps/generatekartu/buatDesain",
        name: "buat desain",
        component: () => import("@/views/apps/generatekartu/buatDesain.vue"),
      },

      // router Sekolah
      {
        path: "/superadmin/sekolah",
        name: "sekolah",
        component: () => import("@/views/superadmin/sekolah/sekolah.vue"),
      },
      {
        path: "/superadmin/sekolah/add",
        name: "add-sekolah",
        component: () => import("@/views/superadmin/sekolah/add-sekolah.vue"),
      },
      {
        path: "/superadmin/sekolah/edit/:_id",
        name: "edit-sekolah",
        component: () => import("@/views/superadmin/sekolah/edit-sekolah.vue"),
      },
      {
        path: "/superadmin/sekolah/detail/:_id",
        name: "detail-sekolah",
        component: () => import("@/views/superadmin/sekolah/detail-sekolah.vue"),
      },
      {
        path: "/superadmin/sekolah/history-upload",
        name: "history-uplad-sekolah",
        component: () => import("@/views/superadmin/sekolah/history-upload-sekolah.vue"),
      },

      {
        path: "/superadmin/sekolah/monitoring",
        name: "monitoring",
        component: () => import("@/views/superadmin/sekolah/monitoring.vue"),
      },

      {
        path: "/superadmin/sekolah/management-proses",
        name: "management-proses",
        component: () => import("@/views/superadmin/sekolah/management-proses.vue"),
      },

      // route siswa
      {
        path: "/superadmin/siswa",
        name: "siswa",
        component: () => import("@/views/superadmin/siswa/siswa.vue"),
      },
      {
        path: "/superadmin/siswa/add",
        name: "add-siswa",
        component: () => import("@/views/superadmin/siswa/add-siswa.vue"),
      },

      {
        path: "/superadmin/siswa/edit/:_id",
        name: "edit-siswa",
        component: () => import("@/views/superadmin/siswa/edit-siswa.vue"),
      },
      {
        path: "/superadmin/siswa/detail/:_id",
        name: "detail-siswa",
        component: () => import("@/views/superadmin/siswa/detail-siswa.vue"),
      },
      {
        path: "/superadmin/siswa/history",
        name: "history",
        component: () => import("@/views/superadmin/siswa/history.vue"),
      },
      {
        path: "/superadmin/siswa/history/:processId",
        name: "history-detail",
        component: () => import("@/views/superadmin/siswa/history-detail.vue"),
      },
      {
        path: "/superadmin/siswa/history-upload",
        name: "history-upload-siswa",
        component: () => import("@/views/superadmin/siswa/history-upload-siswa.vue"),
      },
      {
        path: "/superadmin/data-pembelian/:id",
        name: "data-pembelian",
        component: () => import("@/views/superadmin/product/data-pembelian/data-pembelian.vue")
      },
      // {
      //   path: "/superadmin/detail-merchant/:id",
      //   name: "detail-merchant",
      //   component: () => import("@/views/superadmin/product/detail-merchant/detail-merchant.vue")
      // },
      {
        path: "/superadmin/detail-merchant/:id",
        name: "detail-merchant",
        component: () => import("@/views/superadmin/product/detail-merchant/detail-merchant.vue")
      },
      {
        path: "/superadmin/data-merchant",
        name: "data-merchant",
        component: () => import("@/views/superadmin/product/data-merchant/data-merchant.vue")
      },
      {
        path: "/superadmin/history-mapping/:id",
        name: "history-mapping",
        component: () => import("@/views/superadmin/product/history-mapping/history-mapping.vue")
      },
      {
        path: "/superadmin/add-mapping/:id",
        name: "add-mapping",
        component: () => import("@/views/superadmin/product/data-kartu-termapping/add-mapping.vue")
      },
      {
        path: "/superadmin/data-mapping",
        name: "data-mapping",
        component: () => import("@/views/superadmin/product/data-kartu-termapping/data-kartu-termapping.vue")
      },
      {
        path: "/superadmin/daftar-produk",
        name: "daftar-produk",
        component: () => import("@/views/superadmin/product/daftar-produk/daftar-produk.vue")
      },
      {
        path: "/superadmin/master-produk",
        name: "add-produk",
        component: () => import("@/views/superadmin/product/daftar-produk/list-produk.vue"),
      },
      {
        path: "/superadmin/data-order",
        name: "data-order",
        component: () => import("@/views/superadmin/product/data-order/data-order.vue"),
      },
      {
        path: "/superadmin/data-order/detail/:id",
        name: "detail-order",
        component: () => import("@/views/superadmin/product/data-order/detail-data-order.vue"),
      },
      {
        path: "/superadmin/data-order/edit/:id",
        name: "edit-order",
        component: () => import("@/views/superadmin/product/data-order/edit-data-order.vue"),
      },
      {
        path: "/superadmin/data-order/service/:id",
        name: "detail-order-service",
        component: () => import("@/views/superadmin/product/data-order/detail-data-order-service.vue"),
      },
      {
        path: "/superadmin/detail-mapping/:id/:batch",
        name: "detail-mapping",
        component: () => import("@/views/superadmin/product/data-kartu-termapping/detail-mapping.vue")
      },


      {
        path: "/superadmin/data-pesanan/detail/:id",
        name: "detail-pesanan",
        component: () => import("@/views/superadmin/product/data-pesanan/detail-pesanan.vue"),
      },
      {
        path: "/superadmin/data-pesanan",
        name: "data-pesanan",
        component: () => import("@/views/superadmin/product/data-pesanan/data-pesanan.vue"),
      },
      // {
      //   path: "/superadmin/siswa/detail/:_id",
      //   name: "detail-siswa",
      //   component: () => import("@/views/superadmin/siswa/detail-siswa.vue"),
      // },

      // menu finance
      // {
      //   path: "/superadmin/finance",
      //   name: "finance",
      //   component: () => import("@/views/superadmin/finance/finance.vue"),
      // },
      // {
      //   path: "/superadmin/add-finance-invoice",
      //   name: "add-finance-invoice",
      //   component: () => import("@/views/superadmin/finance/add-finance-invoice.vue"),
      // },
      // {
      //   path: "/superadmin/edit-finance/:_id",
      //   name: "edit-finance",
      //   component: () => import("@/views/superadmin/finance/edit-finance.vue"),
      // },
      // {
      //   path: "/superadmin/view-finance/:_id",
      //   name: "view-finance",
      //   component: () => import("@/views/superadmin/finance/view-finance.vue"),
      // },
      {
        path: "/superadmin/invoices",
        name: "invoices",
        component: () => import("@/views/superadmin/finance/invoice/invoices.vue"),
      },
      {
        path: "/superadmin/konfirmasi-invoice",
        name: "konfirmasi-invoice",
        component: () => import("@/views/superadmin/finance/invoice/konfirmasiInvoice.vue"),
      },

      {
        path: "/superadmin/checkout-invoices",
        name: "checkout-invoices",
        props: true,
        component: () => import("@/views/superadmin/finance/invoice/checkout-invoices.vue"),
      },
      {
        path: "/superadmin/view-detail-invoices/:_id",
        name: "view-detail-invoices",
        component: () => import("@/views/superadmin/finance/invoice/view-detail-invoice.vue"),
      },
      {
        path: "/superadmin/history-pembayaran-manual",
        name: "history-pembayaran-manual",
        component: () => import("@/views/superadmin/finance/invoice/history-pembayaran-manual.vue"),
      },
      // {
      //   path: "/superadmin/add-special-case",
      //   name: "add-special-case",
      //   component: () => import("@/views/superadmin/finance/add-special-case.vue")
      // },

      // Setting Invoices
      {
        path: "/superadmin/setting-invoices",
        name: "setting-invoice",
        component: () => import("@/views/superadmin/finance/setting/setting-invoices.vue"),
      },
      {
        path: "/superadmin/add-setting-invoices/",
        name: "add-setting-invoices",
        component: () => import("@/views/superadmin/finance/setting/add-setting-invoices.vue")
      },
      {
        path: "/superadmin/edit-setting-invoices/:_id",
        name: "edit-setting-invoices",
        component: () => import("@/views/superadmin/finance/setting/edit-setting-invoices.vue")
      },

      // Setting Invoices Product
      {
        path: "/superadmin/setting-invoices-product",
        name: "setting-invoice-product",
        component: () => import("@/views/superadmin/finance/setting-product/setting-invoices.vue"),
      },
      {
        path: "/superadmin/add-setting-invoices-product/",
        name: "add-setting-invoices-product",
        component: () => import("@/views/superadmin/finance/setting-product/add-setting-invoices.vue")
      },
      {
        path: "/superadmin/edit-setting-invoices-product/:_id",
        name: "edit-setting-invoices-product",
        component: () => import("@/views/superadmin/finance/setting-product/edit-setting-invoices.vue")
      },
      // {
      //   path: "/superadmin/dokumen-finance/",
      //   name: "dokumen-finance",
      //   component: () => import("@/views/superadmin/finance/dokumen/invoice.vue")
      // },
      // {
      //   path: "/superadmin/view-document-finance",
      //   name: "view-document-finance",
      //   component: () => import("@/views/superadmin/finance/dokumen/view-document-finance.vue")
      // },

      // Log VA
      {
        path: "/superadmin/log-create-va",
        name: "log-create-va",
        component: () => import("@/views/superadmin/finance/log/log-create-va.vue")
      },
      {
        path: "/superadmin/log-callback-va",
        name: "log-callback-va",
        component: () => import("@/views/superadmin/finance/log/log-callback-va.vue")
      },
      {
        path: "/superadmin/daftar-product",
        name: "daftar-product",
        component: () => import("@/views/superadmin/product/daftar-product/daftar-product.vue")
      },
      {
        path: "/superadmin/daftar-product/detail/:_id",
        name: "detail-daftar-product",
        component: () => import("@/views/superadmin/product/daftar-product/detail-daftar-product.vue"),
      },
      {
        path: "/superadmin/data-product",
        name: "data-product",
        component: () => import("@/views/superadmin/product/data-product/data-product.vue")
      },
      {
        path: "/superadmin/data-product/add",
        name: "add-data-product",
        component: () => import("@/views/superadmin/product/data-product/add-data-product.vue"),
      },
      {
        path: "/superadmin/data-product/edit/:_id",
        name: "edit-data-product",
        component: () => import("@/views/superadmin/product/data-product/edit-data-product.vue"),
      },
      {
        path: "/superadmin/data-product/detail/:_id",
        name: "detail-data-product",
        component: () => import("@/views/superadmin/product/data-product/detail-data-product.vue"),
      },

      {
        path: "/superadmin/card-mapping",
        name: "card-mapping",
        component: () => import("@/views/superadmin/product/card-mapping/card-mapping.vue")
      },
      {
        path: "/superadmin/card-design",
        name: "card-design",
        component: () => import("@/views/superadmin/product/card-design/card-design.vue")
      },
      {
        path: "/superadmin/card-design/history-upload",
        name: "history-upload-card-design",
        component: () => import("@/views/superadmin/product/card-design/history-upload.vue")
      },
      {
        path: "/superadmin/card-design/history-upload-photo",
        name: "history-upload-photo-card-design",
        component: () => import("@/views/superadmin/product/card-design/history-upload-photo.vue")
      },
      {
        path: "/superadmin/card-design/history-upload-card-status",
        name: "history-upload-card-status",
        component: () => import("@/views/superadmin/product/card-design/history-upload-card-status.vue")
      },
      {
        path: "/superadmin/cart-product",
        name: "cart-product",
        component: () => import("@/views/superadmin/product/cart-product/cart-product.vue")
      },
      {
        path: "/superadmin/cart-product/detail-order/:_id/:cartId",
        name: "detail-order-service",
        component: () => import("@/views/superadmin/product/detail-order/detail-order.vue")
      },
      {
        path: "/superadmin/cart-product/detail-list-card/:_id/:cartId",
        name: "detail-list-card",
        component: () => import("@/views/superadmin/product/detail-order/list-card.vue")
      },
      {
        path: "/superadmin/cart-product/detail-product-order/:_id/:cartId",
        name: "detail-order",
        component: () => import("@/views/superadmin/product/detail-order/detail-cart-product-type.vue")
      },
    ],
  },



  // role SEKOLAH
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      // dashboard
      {
        path: "/sekolah/dashboard",
        name: "sekolah-dashboard",
        component: () => import("@/views/sekolah/dashboard/dashboard.vue"),
      },

      // notifikasi
      {
        path: "/sekolah/notification",
        name: "sekolah-notification",
        component: () => import("@/views/sekolah/notification/notification.vue"),
      },

      // sekolah
      {
        path: "/sekolah/sekolah",
        name: "sekolah-sekolah",
        component: () => import("@/views/sekolah/sekolah/sekolah.vue"),
      },
      {
        path: "/sekolah/sekolah/edit/:_id",
        name: "sekolah-edit-sekolah",
        component: () => import("@/views/sekolah/sekolah/edit-sekolah.vue"),
      },
      {
        path: "/sekolah/sekolah/detail/:_id",
        name: "sekolah-detail-sekolah",
        component: () => import("@/views/sekolah/sekolah/detail-sekolah.vue"),
      },

      // siswa
      {
        path: "/sekolah/siswa",
        name: "sekolah-siswa",
        component: () => import("@/views/sekolah/siswa/siswa.vue"),
      },
      {
        path: "/sekolah/siswa/add",
        name: "sekolah-add-siswa",
        component: () => import("@/views/sekolah/siswa/add-siswa.vue"),
      },
      {
        path: "/sekolah/siswa/edit/:_id",
        name: "sekolah-edit-siswa",
        component: () => import("@/views/sekolah/siswa/edit-siswa.vue"),
      },
      {
        path: "/sekolah/siswa/history",
        name: "sekolah-history",
        component: () => import("@/views/sekolah/siswa/history.vue"),
      },
      {
        path: "/sekolah/siswa/history/:processId",
        name: "sekolah-history-detail",
        component: () => import("@/views/sekolah/siswa/history-detail.vue"),
      },
      {
        path: "/sekolah/siswa/history-upload",
        name: "sekolah-history-upload-siswa",
        component: () => import("@/views/sekolah/siswa/history-upload-siswa.vue"),
      },

      // router legal - legalitas
      {
        path: "/sekolah/legal/legalitas",
        name: "legal-legalitas-sekolah",
        component: () => import("@/views/sekolah/legal/legalitas/legalitas.vue"),
      },
      {
        path: "/sekolah/legal/legalitas/detail/:id",
        name: "detail-legalitas-sekolah",
        component: () => import("@/views/sekolah/legal/legalitas/detail-legalitas.vue"),
      },
      {
        path: "/sekolah/legal/legalitas/detail/update/:idSekolah/:idDokumen",
        name: "detail-update-legalitas-sekolah",
        component: () => import("@/views/sekolah/legal/legalitas/detail-edit-legalitas.vue"),
      },

      // router product - card desain
      {
        path: "/sekolah/card-design",
        name: "card-design-sekolah",
        component: () => import("@/views/sekolah/product/card-design/card-design.vue"),
      },
      {
        path: "/sekolah/card-design/history-upload",
        name: "history-upload-card-design-sekolah",
        component: () => import("@/views/sekolah/product/card-design/history-upload.vue")
      },
      {
        path: "/sekolah/card-design/history-upload-photo",
        name: "history-upload-photo-card-design-sekolah",
        component: () => import("@/views/sekolah/product/card-design/history-upload-photo.vue")
      },
      {
        path: "/sekolah/card-design/history-upload-card-status",
        name: "history-upload-card-status-sekolah",
        component: () => import("@/views/sekolah/product/card-design/history-upload-card-status.vue")
      },
      {
        path: "/sekolah/invoices",
        name: "invoices-sekolah",
        component: () => import("@/views/sekolah/finance/invoice/invoices.vue"),
      },
      {
        path: "/sekolah/checkout-invoices",
        name: "checkout-invoices-sekolah",
        props: true,
        component: () => import("@/views/sekolah/finance/invoice/checkout-invoices.vue"),
      },
      {
        path: "/sekolah/view-detail-invoices/:_id",
        name: "view-detail-invoices-sekolah",
        component: () => import("@/views/sekolah/finance/invoice/view-detail-invoice.vue"),
      },
      {
        path: "/sekolah/log-create-va",
        name: "log-create-va-sekolah",
        component: () => import("@/views/sekolah/finance/log/log-create-va.vue")
      },
      {
        path: "/sekolah/log-callback-va",
        name: "log-callback-va-sekolah",
        component: () => import("@/views/sekolah/finance/log/log-callback-va.vue")
      },
    ],
  },


  // AUTH
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/sign-up-form/:otp/:regId/:email",
        name: "sign-up-form",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUpForm.vue"),
      },
      {
        path: "/sign-up/input-password",
        name: "sign-up-input-password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUpInputPassword.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/crafted/user-view",
        name: "user-view",
        component: () =>
          import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/password-reset/:otp/:regId/:email",
        name: "password-reset-create-password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordResetCreatePassword.vue"),
      },
    ],
  },

  {
    path: "/sign-up/check-email",
    name: "check-email",
    component: () =>
      import("@/views/crafted/authentication/basic-flow/ThanksPage.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/open-invoices/:id",
    name: "open-invoices",
    component: () => import("@/views/apps/newInvoices.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/detail-open-invoices/:_id",
    name: "detail-open-invoices",
    component: () => import("@/views/apps/detail-open-invoice.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
