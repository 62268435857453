import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { useToast } from "vue-toastification";
import MenuRoleSekolah from "@/core/config/MenuRoleSekolah";
import qs from "querystring";
import { useRouter, useRoute } from "vue-router";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
  username: string;
  active: boolean;
  companyId: string;
  companyName: string;
  dateOfBirth: string;
  userId: string;
  role: Array<string>;
  roleContoh: string;
  phone: string;
  lang: string;
  isWa: boolean;
  isFirstLogin: boolean;
  isAdmin: boolean;
  image: string;
  access_token: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}
export interface Notif {
  notif: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }
  @Mutation
  [Mutations.SET_NOTIF](error) {
    console.log(error)
  }


  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    JwtService.saveToken(this.user.access_token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("crmv2/main_auth/auth/login", qs.stringify(credentials))
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          localStorage.setItem("user_account", JSON.stringify(data));


          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.detail);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    localStorage.clear();
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("api_default/auth/register", qs.stringify(credentials))
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.detail);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.detail);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {

    const toast = useToast();
    const router = useRouter();

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.postWithoutSlug("crmv2/main_auth/auth/refresh_token")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {

          toast.error(response.data.detail)

          router.push('/sign-in');

          this.context.commit(Mutations.SET_ERROR, response.data.detail);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.VERIFY_AUTH_BANK]() {

    const toast = useToast();
    const router = useRouter();

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.postWithoutSlug("crmv2/main_bank/credential/refresh_token")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {

          toast.error(response.data.detail)

          router.push('/sign-in');

          this.context.commit(Mutations.SET_ERROR, response.data.detail);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.VERIFY_AUTH_SEKOLAH]() {
    const toast = useToast();
    const router = useRouter();

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.postWithoutSlug("crmv2/main_school/credential/refresh_token")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          toast.error(response.data.detail)

          router.push('/sign-in');

          this.context.commit(Mutations.SET_ERROR, response.data.detail);
        })
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.VERIFY_AUTH_SISWA]() {
    const toast = useToast();
    const router = useRouter();

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.postWithoutSlug("crmv2/main_student/credential/refresh_token")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          toast.error(response.data.detail)

          router.push('/sign-in');

          this.context.commit(Mutations.SET_ERROR, response.data.detail);
        })
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.VERIFY_AUTH_FINANCE]() {
    const toast = useToast();
    const router = useRouter();

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.postWithoutSlug("crmv2/main_invoice/credential/refresh_token")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          toast.error(response.data.detail)

          router.push('/sign-in');

          this.context.commit(Mutations.SET_ERROR, response.data.detail);
        })
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.VERIFY_AUTH_USER]() {
    const toast = useToast();
    const router = useRouter();

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.postWithoutSlug("crmv2/main_user/credential/refresh_token")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          toast.error(response.data.detail)

          router.push('/sign-in');

          this.context.commit(Mutations.SET_ERROR, response.data.detail);
        })
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  // @Action
  // [Actions.VERIFY_AUTH_INVOICE] () {
  //   const toast = useToast();
  //   const router = useRouter();

  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     ApiService.postWithoutSlug("crmv2/main_invoice/credential/refresh_token")
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_AUTH, data);
  //       })
  //       .catch(( {response }) => {
  //         toast.error(response.data.detail)

  //         router.push('/sign-in');

  //         this.context.commit(Mutations.SET_ERROR, response.data.detail);
  //       })
  //   } else {
  //     this.context.commit(Mutations.PURGE_AUTH);
  //   }
  // }


  @Action
  [Actions.VERIFY_AUTH_NOTIFIKASI]() {
    const toast = useToast();
    const router = useRouter();

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.postWithoutSlug("crmv2/main_invoice/credential/refresh_token")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          toast.error(response.data.detail)

          router.push('/sign-in');

          this.context.commit(Mutations.SET_ERROR, response.data.detail);
        })
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
  @Action
  [Actions.NOTIFIKASI](cek) {
    const toast = useToast();
    const router = useRouter();

    var a = JSON.parse(localStorage.getItem("badgeNotiflegal")!);
    var b = a - 1;
    console.log(cek, a)


  }
}
