import { createApp } from "vue";
import App from "./App.vue";

import Toast, { PluginOptions } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options: PluginOptions = {
  // You can set your default options here
};

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
// import router from "./router";
import router from "@/router/clean.ts";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
// import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
// import ApiStrapiService from "@/core/services/ApiStrapiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(fas);

import VueCountdown from "@chenfengyuan/vue-countdown";
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

app.use(Toast, options);
app.component(VueCountdown.name, VueCountdown);
app.component("font-awesome-icon", FontAwesomeIcon);

ApiService.init(app);
// ApiStrapiService.init(app);
// MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
